<template>
    <div class="container min-height">
        <top active="1"></top>
        <img src="../assets/image/1546.png" width="100%" alt="" />
        <div class="main">
            <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                <!-- <div class="box-shadow br-reg bg-white flex-column justify-content-spaceBetween align-items-center" style="width: 15%">
                    <p class="fs-mid-big black margin-bs">作品登记</p>
                    <p class="fs-mid grey margin-b">线上登记版权作品</p>
                    <el-button class="register-btn margin-ts" size="small" round @click="$router.push('/register')">开始登记</el-button>
                </div> -->
                <div style="width: 100%; margin-bottom: 2%" class="flex-row align-items-center justify-content-spaceBetween">
                    <div style="width: 15%; padding: 4% 0" class="br-reg box-shadow bg-white flex-column align-items-center justify-content-center">
                        <p class="fs-mid-big black margin-bs">作品登记</p>
                        <p class="fs-mid grey margin-b">线上登记版权作品</p>
                        <el-button class="register-btn margin-ts" type="primary" size="small" round @click="register()">开始登记</el-button>
                    </div>
                    <el-image :src="require('../assets/image/1705.png')" style="width: 84%; cursor: pointer" fit="cover" @click="onClickRegister()"></el-image>
                </div>
                <!-- <div class="menu box-shadow br-reg bg-white width-85 flex-row justify-content-spaceBetween align-items-center">
                    <div class="width-10 textAlign-c">
                        <p class="fs-mid-big black margin-bs">作品登记</p>
                        <p class="fs-mid grey margin-b">线上登记版权作品</p>
                        <el-button class="register-btn margin-ts" size="small" round @click="$router.push('/register')">开始登记</el-button>
                    </div>
                    <div class="width-85 flex-row justify-content-spaceBetween align-items-center">
                        <div class="flex-column justify-content-center align-items-center">
                            <img src="../assets/image/1348.png" width="55%" alt="" />
                            <el-button class="mini-btn" size="mini" round>1.作品信息</el-button>
                        </div>
                        <img src="../assets/image/1698.png" height="10" alt="" />
                        <div class="flex-column justify-content-center align-items-center">
                            <img src="../assets/image/1349.png" width="55%" alt="" />
                            <el-button class="mini-btn" size="mini" round>2.著作人信息</el-button>
                        </div>
                        <img src="../assets/image/1698.png" height="10" alt="" />
                        <div class="flex-column justify-content-center align-items-center">
                            <img src="../assets/image/1350.png" width="55%" alt="" />
                            <el-button class="mini-btn" size="mini" round>3.作品基本信息</el-button>
                        </div>
                        <img src="../assets/image/1698.png" height="10" alt="" />
                        <div class="flex-column justify-content-center align-items-center">
                            <img src="../assets/image/1351.png" width="55%" alt="" />
                            <el-button class="mini-btn" size="mini" round>4.权利状况说明</el-button>
                        </div>
                        <img src="../assets/image/1698.png" height="10" alt="" />
                        <div class="flex-column justify-content-center align-items-center">
                            <img src="../assets/image/1352.png" width="55%" alt="" />
                            <el-button class="mini-btn" size="mini" round>5.申请人信息</el-button>
                        </div>
                        <img src="../assets/image/1698.png" height="10" alt="" />
                        <div class="flex-column justify-content-center align-items-center">
                            <img src="../assets/image/1115.png" width="55%" alt="" />
                            <el-button class="mini-btn" size="mini" round>6.签章</el-button>
                        </div>
                    </div>
                </div>
                <div style="width: 15%;margin-bottom: 2.5rem;">
                    <img src="../assets/image/1700.png" width="78%" alt="" @click="onClickRegister()" />
                </div> -->
            </div>
            <div class="width-100 margin-b flex-row justify-content-spaceBetween align-items-center">
                <div class="width-50 flex-row justify-content-start align-items-center">
                    <img src="../assets/image/1548.png" height="24" alt="" />
                    <p class="fs-big black margin-l fw-mid">作品管理</p>
                    <p class="fs-sml grey">（管理已上传登记的作品）</p>
                </div>
                <div class="width-30 flex-row justify-content-end align-items-center">
                    <el-select v-model="value" :clearable="true" placeholder="全部" @change="flashSearvh">
                        <el-option v-for="item in options" :key="item.value" :label="item.text" :value="item.value"> </el-option>
                    </el-select>
                    <el-input v-model="input" class="search margin-l" placeholder="输入作品名称搜索" suffix-icon="el-icon-search" @change="flashSearvh"> </el-input>
                </div>
            </div>
            <div class="width-100 flex-row justify-content-start align-items-star flex-wrap">
                <div v-for="(item, index) in list" :class="{ 'ln-red': item.status == 4, 'ln-green': item.status == 3, 'ln-orange': item.status == 2, 'ln-blue': item.status == 1 }" :key="index" class="list box-shadow br-reg bg-white">
                    <p class="width-100 margin-b flex-row justify-content-spaceBetween align-items-center">
                        <span class="fs-big black">{{ item.title }}</span>
                        <span :class="{ red: item.status == 4, green: item.status == 3, orange: item.status == 2, blue: item.status == 1 }" class="fs-mid textAlign-r">{{ item.statusName }}</span>
                    </p>
                    <div class="width-100 grey flex-row justify-content-spaceBetween align-items-star flex-wrap">
                        <p class="fs-mid margin-bs width-50">著作权人：{{ item.ownerName }}</p>
                        <p class="fs-mid margin-bs width-50">作品作者：{{ item.authorName }}</p>
                        <p class="fs-mid margin-b width-50">首次填报时间：{{ item.createTime }}</p>
                        <p class="fs-mid margin-b width-50">更新时间：{{ item.updateTime }}</p>
                    </div>
                    <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                        <p class="fs-sml grey">{{ item.govReviewRemark ? `不通过原因：${item.govReviewRemark}` : '' }}</p>
                        <p class="fs-sml greyBlue">{{ item.status === 7 ? `版权证书请从“浙里办”下载，登录账号和版权登记系统登录一样的手机号，进入系统在右下角“我的”里“证书下载”。如果显示为空，请刷新系统登录或者稍后登录。` : '' }}</p>
                        <div class="flex-row justify-content-end align-items-center">
                            <el-button v-if="item.status == 0 || item.status == 21 || item.status == 31" size="mini" class="blue-btn margin-l" @click="$router.push({ path: '/register', query: { copyrightId: item.id } })">修改</el-button>
                            <el-button v-if="item.status == 1" size="mini" class="blue-btn margin-l" @click="signature(item.id)">签章</el-button>
                            <el-button v-if="item.status == 11" size="mini" class="blue-btn margin-l" @click="signature(item.id)">缴费</el-button>
                            <!-- <el-button v-if="item.state == 0" size="mini" class="blue-btn margin-l">提交审核</el-button> -->
                            <el-button v-if="item.status != 1 && item.status != 11" :class="{ 'red-btn': item.status == 4, 'green-btn': item.status == 3, 'orange-btn': item.status == 2 }" size="mini" class="margin-l" @click="$router.push({ path: '/register', query: { copyrightId: item.id, edit: 'false' } })">查看</el-button>
                        </div>
                    </div>
                </div>
                <div v-if="list.length === 0" class="textAlign-c width-100 margin-t margin-b">
                    <el-empty :image="require('../assets/image/1176.png')" :image-size="210" description="您还没有上传登记的作品哦"></el-empty>
                </div>
            </div>
            <div class="width-100 textAlign-r">
                <el-pagination v-model="pageNum" :total="total" :page-size="pageSize" layout="prev, pager, next" @current-change="changePage"> </el-pagination>
            </div>
        </div>
        <el-dialog :visible.sync="show" title="" width="20%" center>
            <div class="width-100 flex-column justify-content-start align-items-center">
                <div class="bg-grey ewm">
                    <vue-qr :text="url"></vue-qr>
                </div>
                <p class="fs-sml black margin-t width-100 textAlign-c" style="color: red">签章位置：{{ type === '0' ? '著作权人' : '作者' }}</p>
                <p class="fs-sml black margin-t width-100 textAlign-c">待签章人：{{ name }}</p>
                <p class="fs-sml black margin-t width-100 textAlign-c">待签章人身份证：{{ idcard }}</p>
                <p class="fs-sml black margin-t width-100 textAlign-c">请使用浙里办扫描去签章</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="show = false">我知道了</el-button>
            </span>
        </el-dialog>
        <p class="sign-off textAlign-c grey line-height2 margin-b">
            中共瑞安市委宣传部（瑞安市新闻出版局）©版权所有<br />
            瑞安市数据管理发展有限公司 技术支持<br />
            如有问题咨询服务热线：13957349092
        </p>
    </div>
</template>
<script>
import { getCopyrightBriefly } from '@/api/copyright';
import { getDict } from '@/api/dict';
import vueQr from 'vue-qr';
// import { getSignatureUrl } from '@/api/copyright';
import { getValueByText } from '@/util/helper';

import top from '../components/top.vue';
export default {
    name: 'Index',
    components: {
        top,
        vueQr
    },
    data() {
        return {
            input: '',
            options: [],
            value: '',
            list: [],
            pageNum: 1,
            pageSize: 4,
            total: 0,
            show: false,
            name: '',
            idcard: '',
            type: '0',
            url: ''
        };
    },
    async created() {
        this.options = await getDict('bqsb_copyright_status');
        // const dist = await getDict('bqsb_button_msg');
        await this.flashList();
    },
    methods: {
        async initMsg() {
            const dist = await getDict('bqsb_button_msg');
            // console.log(dist);
            if (String(getValueByText(dist, 'auditStatus')) === '3.1') return false;
            if (dist.length === 0) return false;
            if (dist[0].text !== 'true') return false;
            return dist[1].text;
        },
        async flashList() {
            const list = await getCopyrightBriefly({ pageNum: this.pageNum, pageSize: this.pageSize, title: this.input, status: this.value });
            this.total = list.total;
            this.list = list.rows;
        },
        async signature(copyrightId) {
            // this.$router.push({ path: '/register', query: { copyrightId, step: 5 } });
            this.$router.push({ path: '/signature', query: { copyrightId } });
            // const signature = await getSignatureUrl(copyrightId);
            // this.name = signature.data.info.ownerName;
            // this.idcard = signature.data.info.cardNo;
            // this.url = signature.data.url;
            // this.type = signature.data.type;
            // this.show = true;
        },
        async changePage(ind) {
            this.pageNum = ind;
            await this.flashList();
        },
        async flashSearvh() {
            this.pageNum = 1;
            await this.flashList();
        },
        async onClickRegister() {
            // this.$router.push({ path: '/register', query: { copyrightActivity: '梅源设计大赛参赛作品' } });
            this.$alert('梅源版权设计大赛已结束报名！', '提示', {
                confirmButtonText: '确定',
                callback: () => {}
            });
        },
        async register() {
            // this.$router.push('/register');
            const msg = await this.initMsg();
            if (!msg) {
                this.$router.push('/register');
            } else {
                this.$alert(msg, '提示', {
                    confirmButtonText: '确定',
                    callback: () => {}
                });
            }
        }
    }
};
</script>

<style lang="less" scoped>
.container {
    .main {
        width: 100%;
        padding: 0 10%;
        position: relative;
        top: -50px;
        .menu {
            padding: 40px 2%;
            margin-bottom: 40px;
            .register-btn {
                background: linear-gradient(to right, #007aff, #8cc3ff);
                border: none;
                color: #ffffff;
            }
        }
        .mini-btn,
        .blue-btn {
            border-color: rgb(72, 149, 228);
            color: rgb(72, 149, 228);
        }
        .red-btn {
            border-color: #ff3b30;
            color: #ff3b30;
        }
        .orange-btn {
            border-color: #ff9500;
            color: #ff9500;
        }
        .green-btn {
            border-color: #4cd964;
            color: #4cd964;
        }
        .ln-blue {
            background: linear-gradient(to right top, white 60%, #e9f3ff);
        }
        .ln-green {
            background: linear-gradient(to right top, white 60%, #e9fff8);
        }
        .ln-orange {
            background: linear-gradient(to right top, white 60%, #fff4e9);
        }
        .ln-red {
            background: linear-gradient(to right top, white 60%, #ffede9);
        }
        .search {
            /deep/.el-input__inner {
                background-color: #f7f7f7;
                border: none;
            }
        }
        .list {
            width: 48%;
            margin: 0 1% 20px;
            padding: 20px 2%;
        }
    }
}
/deep/.el-pager li {
    font-size: 1rem;
}
</style>
